import TrialBanner from "components/common/footer-banners/TrialBanner";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import GlobalLayout from "components/page/GlobalLayout";
import OnDemandVideoHeader from "components/webinar/on-demand/OnDemandVideoHeader";
import debug from "debug";
import React, { useEffect } from "react";
import Helmet from "react-helmet";

const log = debug("Thank-You");

export default function OnDemandThankYouPage({
  videoId,
  title,
  description,
  metaTitle = "",
  metaDescription = "",
  children = null,
}) {
  useEffect(() => {
    if (window && window.ga) {
      log("google conversion lead was tracked");
      window.ga("event", "conversion", {
        send_to: "AW-970586936/DNEjCJ7f15UCELj2584D",
      });
    }
  }, []);

  return (
    <GlobalLayout color="var(--gradient-light)">
      {!!metaTitle && <title>{metaTitle}</title>}
      {!!metaDescription && (
        <meta name="description" content={metaDescription} />
      )}
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <script
          src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
        <script>
          {`
            email = localStorage.getItem("email");
            firstname = localStorage.getItem("firstname");
            lastname = localStorage.getItem("lastname");
            phone = localStorage.getItem("phone");
            title = localStorage.getItem("jobtitle");
            employees = localStorage.getItem("numberofemployees2");
            company = localStorage.getItem("company");
        
            window.referralJS = ((window.referralJS !== null && window.referralJS !== undefined) ? window.referralJS : {});
            window.referralJS.conversion = {
              debug: "false",
              parameters: {
                email: email,
                firstname: firstname,
                lastname: lastname,
                title: title,
                employees: employees,
                phone: phone,
                companyName: company
              }
            };
          `}
        </script>
      </Helmet>
      <OnDemandVideoHeader
        title={title}
        description={description}
        videoId={videoId}
      />
      {children ? { ...children } : <></>}
      <IndustryLeadersCards />
      <TrialBanner />
    </GlobalLayout>
  );
}
