import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      videoId="uekuhxqlx4"
      title="How Rhombus Can Help Texas Schools Improve Emergency Response with Panic Buttons"
      description="Discover how Rhombus can help school districts meet requirements of Texas SB 838 to provide each classroom with silent panic alert technology to alert emergency services."
    />
  );
}
